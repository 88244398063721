<template>
  <div>
    <div class="row">
      <div class="col-6">
        <div class="card shadow-sm">
          <div class="card-body">
            <div class="card-title">
              <strong>Summary of Requests for Serial Number </strong>
            </div>
            <table class="table table-sm table-bordered">
              <thead>
                <th>HEI</th>
                <th>Component</th>
                <th>Date Requested</th>
                <th>No. of Students</th>
                <th>Issued on</th>
                <th>Duration</th>
              </thead>
              <tbody>
                <tr>
                  <td>CSU-MAIN</td>
                  <td>CWTS</td>
                  <td>June 24, 2023 11:00 AM</td>
                  <td>55</td>
                  <td>June 25, 2023 8:00 AM</td>
                  <td>1 day</td>
                </tr>
                <tr>
                  <td>ASSCAT</td>
                  <td>LTS</td>
                  <td>June 24, 2023 11:00 AM</td>
                  <td>43</td>
                  <td>June 25, 2023 8:00 AM</td>
                  <td>1 day</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="col-3">
        <div class="card rounded shadow-sm">
          <div class="card-body">
            <div class="card-title">
              <strong>Serial Numbers</strong>
            </div>
            <div class="row">
              <div class="col-auto">
                <div class="form-group">
                  <label for="">Academic Year</label>
                  <select class="form-control col-auto" name="" id="">
                    <option value="" selected>All</option>
                    <option v-for="awardYear in awardYears" :key="awardYear">
                      {{ awardYear }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="col-auto">
                <div class="form-group">
                  <label for="">Component</label>
                  <select class="form-control col-auto" name="" id="">
                    <option value="" selected>All</option>
                    <option v-for="component in components" :key="component">
                      {{ component }}
                    </option>
                  </select>
                </div>
              </div>
            </div>

            <div class="">
              <table class="table table-sm table-bordered">
                <caption>
                  *Issuance of serial number through the portal started on Feb.
                  27, 2023
                </caption>
                <thead>
                  <th>A.Y.</th>
                  <th>COMPONENT</th>
                  <th>LAST ISSUED NUMBER</th>
                </thead>
                <tbody>
                  <tr v-for="log in logs" :key="log.id">
                    <td>
                      {{ log.award_year }}
                    </td>
                    <td>{{ log.component }}</td>
                    <th>{{ log.last_number_issued }}</th>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <div class="col">
        <div class="card shadow-sm">
          <div class="card-body">
            <div class="card-title">
              <strong>Status</strong>
            </div>

            <div class="form-group">
              <label for="">Academic Year</label>
              <select class="form-control col-auto" name="" id="">
                <option value="" selected>All</option>
                <option v-for="awardYear in awardYears" :key="awardYear">
                  {{ awardYear }}
                </option>
              </select>
            </div>

            <ul class="list-group">
              <li
                class="list-group-item d-flex justify-content-between align-items-center"
              >
                PENDING
                <span class="badge badge-primary badge-pill">14</span>
              </li>
              <li
                class="list-group-item d-flex justify-content-between align-items-center"
              >
                ONGOING-NSTP1
                <span class="badge badge-primary badge-pill">2</span>
              </li>
              <li
                class="list-group-item d-flex justify-content-between align-items-center"
              >
                ONGOING-NSTP2
                <span class="badge badge-primary badge-pill">1</span>
              </li>
              <li
                class="list-group-item d-flex justify-content-between align-items-center"
              >
                COMPLETED
                <span class="badge badge-primary badge-pill">1</span>
              </li>
              <li
                class="list-group-item d-flex justify-content-between align-items-center"
              >
                REQUESTED FOR SERIAL
                <span class="badge badge-primary badge-pill">1</span>
              </li>
              <li
                class="list-group-item d-flex justify-content-between align-items-center"
              >
                WITH SERIAL
                <span class="badge badge-success badge-pill">1</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Reports',
  props: {
    awardYears: {
      required: true,
      type: Array,
    },
    components: {
      required: true,
      type: Array,
    },
  },
  data() {
    return {
      logs: [],
    };
  },
  methods: {
    getNstpLogs() {
      axios.get('api/nstp/logs/all').then((res) => {
        this.logs = res.data;
      });
    },
  },
  mounted() {
    this.getNstpLogs();
  },
};
</script>
